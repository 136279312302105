/**********************************************************************************************************************************************/
/* font styles */
@font-face {
  font-family: Centra;
  src: url(./assets/font/CentraNo2-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url(./assets/font/CentraNo2-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url(./assets/font/CentraNo2-Book.ttf);
  font-weight: 400;
}

/**********************************************************************************************************************************************/
/* default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

/**********************************************************************************************************************************************/
/* NavBar styling */
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.logo {
  filter: invert(100%);
  width: 100px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 27, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.5s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.5s ease-in-out;
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.5s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.5s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 0.5s linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon::after,
nav.navbar .navbar-toggler-icon::before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 0.5s linear;
}

nav.navbar .navbar-toggler-icon::after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/**********************************************************************************************************************************************/
/* Banner Styles */
#profile-picture {
  width: 52vh;
  height: 52vh;
  border-radius: 50%;
  background-color: rgba(217, 27, 217, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  animation: updown 10s linear infinite;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 27, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

#profile-picture img {
  border-radius: 50%;
  width: 50vh;
  height: 50vh;
  object-fit: cover;
}

.banner {
  height: 100vh;
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url("./assets/img/banner-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.banner .tagline {
  color: #b8b8b8;
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  color: #b8b8b8;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  background-color: transparent;
  color: #b8b8b8;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/**********************************************************************************************************************************************/
/* Skills Styles */
.skill {
  margin-top: 0;
  padding: 5% 0;
  background-image: url("./assets/img/skills-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
}

.skill-box {
  background-color: #151515;
  display: grid;
  place-items: center;
  width: 80%;
  border-radius: 5%;
}

.skill-title {
  font-weight: 700;
  color: white;
  padding-top: 50px;
}

.skill-description {
  font-weight: 500;
  color: #b8b8b8;
  text-align: center;
  padding-top: 30px;
}

.skills-sphere {
  position: relative;
  top: 0;
  width: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagcloud {
  display: inline-block;
  top: 0;
  left: 0;
  font-weight: 700;
  letter-spacing: 0.0625em;
  font-size: 1.3em;
}

.tagcloud--item {
  color: white;
  text-transform: uppercase;
}

.tagcloud--item:hover {
  cursor: pointer;
  color: blueviolet;
}

.skills-meter {
  display: grid;
  place-items: center;
  margin-left: 200px;
}

.skills-meter-bar {
  border: 2px solid white;
  padding: 200px 50px;
  font-size: 18px;
  border-radius: 2%;
  position: relative;
  background-color: blueviolet;
  transition: 1s ease-in-out;
  cursor: pointer;
  --bar-height: 0%;
}

.skills-meter-bar::before {
  content: "";
  height: var(--bar-height);
  width: 100%;
  position: absolute;
  background-color: #121212;
  left: 0;
  top: 0;
  z-index: 0;
  transition: 1s ease-in-out;
}

.skills-meter h5 {
  color: white;
  margin-top: 20px;
}


/**********************************************************************************************************************************************/
/* Projects Styles */
.proj-imgbx img {
  height: 200px;
}

.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}

.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  color: white;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.proj-imgbx {
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: white;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  color: #B8B8B8;
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx h6 {
  color: yellow;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.proj-links a button {
  background-color: transparent;
  color: #b8b8b8;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.8px;
  border-radius: 20px 20px 20px 20px; /* Adjust the radius values to control the roundness */
  border: none; /* Remove default button border */
  padding: 0px 20px; /* Adjust padding as needed */
}

.proj-links a button:hover{
  background-color: grey;
}


/**********************************************************************************************************************************************/
/* Projects Styles */
.contact {
  background-image: url("./assets/img/contact-bg.png");
  margin-top: 0;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact img {
  width: 50vh;
}

.contact h2 {
  color: white;
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.5s ease-in-out;
}

.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255);
  color: #121212;
}

.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}


/**********************************************************************************************************************************************/
/* Footer Styles */
.footer {
  padding: 20px 0 20px 0;
  background: black;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 10px;
  margin-bottom: 10px;
}